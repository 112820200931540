import React, { FC } from 'react';
import { graphql, PageProps, Link as GatsbyLink } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { postStyle } from '../templates/postStyle';
import { SEO, SiteMetadata } from '../components/seo/seo';
import { DefaultHeader } from '../components/Header/Header';
import { Card } from '../components/Card/Card';
import { CardHeader } from '../components/Card/CardHeader';
import { CardBody } from '../components/Card/CardBody';
import { Button } from '../components/Button/Button';
import Img, { FixedObject, FluidObject } from 'gatsby-image';
import { Schedule, EventAvailable, Assignment, FormatAlignLeft, FormatAlignRight } from '@material-ui/icons';
import { DefaultFooter } from '../components/Footer/Footer';
import { Parallax } from '../components/Parallax/Parallax';
import { infoColor, primaryColor, successColor, warningColor } from '../styles/materialKitBase';

type IndexProps = PageProps<{
  site: SiteMetadata;
  posts: {
    edges: {
      node: {
        id: string;
        frontmatter: {
          image: {
            childImageSharp: {
              fluid: FluidObject;
            };
          };
          title: string;
          series: string | null;
          date: string;
          datePretty: string;
          slug: string;
          mainCategory: string;
          author?: string;
          authorAvatar?: {
            childImageSharp: {
              fixed: FixedObject;
            };
          };
        };
        excerpt: string;
        timeToRead: number;
      };
    }[];
  };
}>;

const useStyles = makeStyles(postStyle);

const Homepage: FC<IndexProps> = ({ data }) => {
  const {
    posts: { edges: allPostsNodes },
    site,
  } = data;

  const classes = useStyles();

  return (
    <div>
      <SEO featuredImage="/img/bloggingStation.jpg" />
      <DefaultHeader />

      <Parallax
        image="/img/bloggingStation.jpg"
        className={classes.parallax}
        filter="dark"
        // style={{ height: '100vh', overflow: 'hidden' }}
      >
        <div className={classes.container}>
          <Grid container justify="center">
            <Grid item md={9} className={classes.textCenter}>
              <Typography component="h1" variant="h3" className={classes.title}>
                Technical Blog from TekMi
              </Typography>
              <Typography component="h2" variant="h6" className={classes.title}>
                Everything related to Web Development, including{' '}
                <span style={{ color: successColor[0] }}>Typescript</span>, React,{' '}
                <span style={{ color: primaryColor[1] }}>Gatsby</span>, Node,{' '}
                <span style={{ color: infoColor[0] }}>PHP</span>, Symfony,{' '}
                <span style={{ color: warningColor[0] }}>Laravel</span> and many more
              </Typography>
              <Button color="primary" size="lg" round href="#blog-post-0" style={{ marginRight: '15px' }}>
                <FormatAlignLeft /> Read article
              </Button>
              <Button color="info" size="lg" round component={GatsbyLink} to="/series">
                Explore Series&nbsp;
                <FormatAlignRight />
              </Button>
            </Grid>
          </Grid>
        </div>
      </Parallax>

      <div style={{ background: 'linear-gradient(#212121, #414345)' }}>
        <div className={classes.container}>
          <Grid container justify="center">
            <Grid item xs={12} sm={9} md={9}>
              {allPostsNodes.map(({ node }, index) => {
                const blogLikeCard = Boolean(index % 2 === 0);

                return (
                  <Card
                    id={`blog-post-${index}`}
                    key={node.id}
                    style={{
                      backgroundImage: !blogLikeCard
                        ? `url(${node.frontmatter.image.childImageSharp.fluid.src})`
                        : 'none',
                    }}
                    blog={blogLikeCard}
                    background={!blogLikeCard}
                    className={classes.card4}
                  >
                    {blogLikeCard && (
                      <CardHeader image plain>
                        <GatsbyLink to={`/${node.frontmatter.slug}`}>
                          <Img alt={node.frontmatter.title} fluid={node.frontmatter.image.childImageSharp.fluid} />
                        </GatsbyLink>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${node.frontmatter.image.childImageSharp.fluid.src})`,
                            opacity: '1',
                          }}
                        />
                      </CardHeader>
                    )}
                    <CardBody plain background={!blogLikeCard}>
                      <Typography
                        variant="subtitle2"
                        // @ts-expect-error
                        className={`${classes.cardCategory} ${classes[`cardCategory${index % 4}`]}`}
                      >
                        <EventAvailable />
                        <time dateTime={node.frontmatter.date}>{node.frontmatter.datePretty}</time>{' '}
                        <span style={{ padding: '0 8px', lineHeight: '1.5rem' }}>|</span>
                        <Schedule />
                        {node.timeToRead} min read {/*<br />*/}
                        <span style={{ padding: '0 8px', lineHeight: '1.5rem' }}>|</span>
                        <Assignment />
                        {node.frontmatter.mainCategory}
                        <span style={{ padding: '0 8px', lineHeight: '1.5rem' }}>|</span>
                        <Avatar
                          src={
                            node.frontmatter.authorAvatar
                              ? node.frontmatter.authorAvatar.childImageSharp.fixed.src
                              : `${site.siteMetadata.siteUrl}${site.siteMetadata.author.avatar}`
                          }
                          alt={node.frontmatter.author ? node.frontmatter.author : site.siteMetadata.author.name}
                          className={classes.avatarSmall}
                        />
                        {node.frontmatter.author ? node.frontmatter.author : site.siteMetadata.author.name}
                      </Typography>
                      <Typography variant="h5" component="h3" className={classes.cardTitle}>
                        <GatsbyLink to={`/${node.frontmatter.slug}`}>{node.frontmatter.title}</GatsbyLink>
                      </Typography>
                      <Typography variant="subtitle1" component="h5" className={classes.description}>
                        {node.excerpt.replace(/^Introduction|To prod/, '')}
                      </Typography>
                      <Button round color="primary" component={GatsbyLink} to={`/${node.frontmatter.slug}`}>
                        Read More
                      </Button>
                      {node.frontmatter.series && (
                        <>
                          <br />
                          <Button round color="info" component={GatsbyLink} to={`/${node.frontmatter.series}`}>
                            Explore &quot;{node.frontmatter.series}&quot; Series
                          </Button>
                        </>
                      )}
                    </CardBody>
                  </Card>
                );
              })}
            </Grid>
          </Grid>
        </div>
      </div>
      <DefaultFooter />
    </div>
  );
};
export default Homepage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        author {
          name
          avatar
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { series_main: { eq: null } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            series
            slug
            date
            datePretty: date(formatString: "DD MMMM, YYYY")
            mainCategory
            image: coverImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author
            authorAvatar {
              childImageSharp {
                fixed(width: 40, height: 40) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          excerpt(pruneLength: 500)
          timeToRead
        }
      }
    }
  }
`;
